<template>
  <a-spin :spinning="processing">
    <h1>En cours...</h1>
  </a-spin>
</template>
<script>
// import Axios from "axios";
import auth from "@/services/auth";
// import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { mapState } from "vuex";

export default {
  created() {
    
    auth.axios().get(`admin/remplir_etat_activite`)
      .then(function(response) {
        alert("Success");
      })
      .catch(() => alert("Error"))
      .finally(() => (this.processing = false));
  },
  computed: {
    ...mapState({
      base_url: (state) => state.auth.base_url,
    }),
  },
  data() {
    return {
      processing: true,
    };
  },
};
</script>
